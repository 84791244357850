const _Data = {}

export const langList = [
    {
        id: 'en',
        name: 'ENG',
        img: './img/american.png',
        desc: "English"
    },
    {
        id: 'my',
        name: 'BUR',
        img: './img/brumese.png',
        desc: 'Burmese'
    }
]

export const menu = [
    {
        href: 'ourService',
        title: 'ourService',
    },
    {
        href: 'ourCilent',
        title: 'ourCilent',
    },
    {
        href: 'footer',
        title: 'contactUs',
    },
]

export const imgSlider = [
    {
        img: 'slider1.jpg',
        title: 'digitalbankingSolution',
        info1: "digitalbankingSolutionBody",
        info2: ''
    },
    {
        img: 'slider2.jpg',
        title: 'digitalWalletSolution',
        info1: 'digitalWalletSolutionBody',
        info2: ''
    },
    {
        img: 'slider3.jpg',
        title: 'digitalAgentSolution',
        info1: 'digitalAgentSolutionBody',
        info2: 'digitalAgentSolutionBody2'
    },
    {
        img: 'slider4.jpg',
        title: 'digitalSalesSulution',
        info1: 'digitalSalesSulutionBody',
        info2: 'digitalSalesSulutionBody2'
    },
    {
        img: 'slider5.jpg',
        title: 'digitalLoyaltySolution',
        info1: 'digitalLoyaltySolutionBody',
        info2: ''
    }
]

export const rightInfo = [
    {
        info1: 'whyChooseUsRight',
        info2: ''
    },
]

export const rightInfoNumber = [
    {
        number: '35',
        titleNumber: 'whyChooseUsRightCustomer'
    },
    {
        number: '4',
        titleNumber: 'whyChooseUsRightMarket'
    },
    {
        number: '99',
        titleNumber: 'whyChooseUsRightProduct'
    },
    {
        number: '70',
        titleNumber: 'whyChooseUsRightResource'
    },
]

export const service = [
    {
        animation: 'down',
        icon: 'service.svg',
        title: 'digitalbankingSolution',
        info: "serviceInfoBankingSolution1",
        info2: "serviceInfoBankingSolution2"
    },
    {
        animation: 'down',
        icon: 'service2.svg',
        title: 'digitalWalletSolution',
        info: 'serviceInfoWalletSolution1',
        info2: "serviceInfoWalletSolution2"
    },
    {
        animation: 'up',
        icon: 'service3.svg',
        title: 'digitalAgentSolution',
        info: 'serviceInfoAgentSolution1',
        info2: 'serviceInfoAgentSolution2'
    },
    {
        animation: 'up',
        icon: 'service4.svg',
        title: 'digitalSalesSulution',
        info: 'serviceInfSalesSulution1',
        info2: 'serviceInfSalesSulution2'
    },
    {
        animation: 'up',
        icon: 'service5.svg',
        title: 'digitalLoyaltySolution',
        info: 'serviceInfoLoyaltySolution1'
    },
]

export const service2 = [
    {
        info: "serviceBankStaff"
    },
    {
        info: "serviceInternetBanking"
    },
    {
        info: "serviceMobileBanking"
    },
    {
        info: "serviceSecurity"
    },
    {
        info: "serviceSupport"
    },
]

export const sliderlogo = [
    {
        img: 'logonew8.svg',
        time: '100',
        img1: 'logonew7.png'
    },
    {
        img: 'logonew4.png',
        time: '200',
        img1: 'logonew3.png'
    },
    {
        img: 'logonew6.png',
        time: '300',
        img1: 'logonew2.png'
    },
    {
        img: 'logonew5.png',
        time: '400',
        img1: 'logonew1.png'
    },
]

export const abu = [
    {
        title: 'founderTitle',
        founderName: 'Ta Yen Thai',
        position: 'founderPosition',
        img: 'bos1.png'
    },
    {
        title: 'chiefTitle',
        founderName: 'Tran Tuan Vu',
        position: 'chiefPosition',
        img: 'bos2.png'
    },
    {
        title: 'headDeliveryDepartmentTitle',
        founderName: 'Vu Tien Dung',
        position: 'headDeliveryDepartmentPosition',
        img: 'bos3.png'
    },
    {
        title: "headRandDDepartmentTitle",
        founderName: 'Nguyen Huong Lan',
        position: 'headRandDDepartmentPosiontion',
        img: 'bos4.png'
    },
    {
        title: 'headBackofficeTitle',
        founderName: 'Nguyen Thi Ly',
        position: 'headBackofficePosition',
        img: 'bos5.png'
    },
]

export const office = [
    {
        icon: 'off1.svg',
        title: 'HANOI HEAD OFFICE, VIETNAM',
        info: '3th floor - AB - Imperia Garden, 203 Nguyen Huy Tuong Street, Thanh Xuan Trung Ward, Thanh Xuan District, Hanoi City',
        number: '(+84) 243 998 9866',
        time: '100'
    },
    {
        icon: 'off2.svg',
        title: 'HO CHI MINH OFFICE, VIETNAM',
        info: '3th floor, Sunrise City Building - North - 27 Nguyen Huu Tho Street, Tan hung Ward, District 7, Ho Chi Minh City',
        number: '(+84) 2836 205 248',
        time: '200'
    },
    {
        icon: 'off3.svg',
        title: 'LAOS P.D.R REPRESENTATIVE OFFICE, LAOS',
        info: '152 Samsenthai Road, Ban Anou, Chanthabuly District, Vietiance Capital',
        number: '(+856) 21 264 591',
        time: '300'
    },
]

export const optionsArray = [
    { key: "au", label: "Australia" },
    { key: "ca", label: "Canada" },
    { key: "us", label: "USA" },
    { key: "pl", label: "Poland" },
    { key: "es", label: "Spain" },
    { key: "fr", label: "France" },
];

export const select = [
    {
        key: 'coreBanking',
        label: 'coreBanking'
    },
    {
        key: 'digitalBanking',
        label: 'digitalBanking'
    },
    {
        key: 'digitalWallet',
        label: 'digitalWallet'
    },
    {
        key: 'middlewarePaymentGateway',
        label: 'middlewarePaymentGateway'
    },
    {
        key: 'apiManagement',
        label: 'apiManagement'
    },
    {
        key: 'digitalLoyalty',
        label: 'digitalLoyalty'
    },
    {
        key: 'rippleConnectGateway',
        label: 'rippleConnectGateway'
    },
    {
        key: 'interbankGateway',
        label: 'interbankGateway'
    },
    {
        key: 'billPaymentSystem',
        label: 'billPaymentSystem'
    },
    {
        key: 'billerHub',
        label: 'billerHub'
    },
    {
        key: 'agencySystem',
        label: 'agencySystem'
    },
    {
        key: 'superApp',
        label: 'superApp'
    }
]

export const firstrow = [
    {
        icon: 'iconFooter2.svg',
        title1: 'dddress',
        title: '3th floor - AB - Imperia Garden, 203 Nguyen Huy Tuong Str, Thanh Xuan Dist, Hanoi.',
    },
    {
        icon: 'iconFooter1.svg',
        title1: 'hours',
        title: '8:30 - 18:00, Mon - Fri',
    },
    {
        icon: 'iconFooter1.svg',
        title1: 'email',
        title: 'contact@digitechs.vn',
        linkEmail: 'contact@digitechs.vn'
    },
    {
        icon: 'iconFooter1.svg',
        title1: 'hotline',
        title: '0243 998 9866',
        tel: "02439989866"
    },
]

export const secondrow = [
    {
        title: 'missionAndVision',
        href: 'introduceCompany'
    },
    {
        title: 'whyDTS',
        href: 'introduceCompany'
    },
    {
        title: 'ourTeam',
        href: 'aboutus'
    },
    {
        title: 'customers',
        href: 'ourCilent'
    },
]

export const thridrow = [
    {
        title: 'customers',
    },
    {
        title: 'newsAndEvents',
    },
    {
        title: 'Careers',
    },
    {
        title: 'support',
    },
]

export default _Data